import { RootState } from "@/app/store"
import { PRODUCT_TOOLS } from "@/pages/MyOrganisation/Users/constants"

export const hasModulePermission = (auth: RootState["auth"], module: string) =>
  auth.userType === "external_admin" ||
  auth.userType === "internal_admin" ||
  auth.permissionModules.includes(module)

export const hasModuleRoutePermission =
  (module: string) => (state: RootState) =>
    hasModulePermission(state.auth, module)

export const hasInternalModuleRoutePermission =
  (module: string) =>
  ({ auth }: RootState) =>
    auth.userType === "internal_admin" ||
    (auth.userType === "internal_restricted_user" &&
      auth.permissionModules.includes(module))

export const hasUnityTestPermission = ({ auth }: RootState) => {
  return auth.userType === "internal_admin"
}

export const callInternalCognitoRegister = async (
  AccessToken: string,
  IdToken: string,
) => {
  const headers = new Headers()
  headers.set("Authorisation", `Bearer ${AccessToken}`)
  headers.set("X-IDENTITY", IdToken!)

  const url = "/auth/user/internal/cognito_create"
  return await fetch(`${import.meta.env.VITE_API_URL}${url}`, {
    headers,
    method: "POST",
  })
}

export const submitHybrid = async (data: any) => {
  const url = `${import.meta.env.VITE_OLD_PORTAL_API_URL}/hybrid-submission`

  const res = await fetch(url, {
    headers: {
      timestamp: Date.now().toString(),
      "content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  })
  return await res.json()
}

export const submitHybridFiles = async (data: any) => {
  const url = `${
    import.meta.env.VITE_OLD_PORTAL_API_URL
  }/hybrid-submission-files`

  return await fetch(url, {
    headers: {
      timestamp: Date.now().toString(),
    },
    method: "POST",
    body: data,
  })
}

export const getMediaPreSignedURL = async (name: string, auth: any) => {
  const headers = new Headers()
  headers.set("Authorisation", `Bearer ${auth!.AccessToken}`)
  headers.set("X-IDENTITY", auth!.IdToken!)

  const url = "/manage_games/get_presigned_url"

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}${url}?path=${name}&expiry_time=3600`,
    {
      headers,
    },
  )
  return await response.json()
}

export const hasInternalReadOnlyAccess = (designation: string | null) => {
  return designation !== PRODUCT_TOOLS
}
