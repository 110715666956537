import ProtectedRoute from "@/components/ProtectedRoute"
import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import {
  CREATIVE_TEAM,
  PRODUCT_MARKETING_MANAGER,
  PUBLISHING_MANAGER,
} from "../MyOrganisation/Users/constants"
import {
  hasInternalModuleRoutePermission,
  hasModulePermission,
} from "@/util/permissions"

const CreativeManagementLayout = lazy(
  () => import("@/pages/CreativeManagement/CreativeManagement"),
)

const route: RouteObject = {
  path: "/games/creative-management",
  element: (
    <ProtectedRoute
      hasPermission={(state) =>
        state.auth.scope === "internal"
          ? hasInternalModuleRoutePermission("creative_management")(state) &&
            [
              PUBLISHING_MANAGER,
              PRODUCT_MARKETING_MANAGER,
              CREATIVE_TEAM,
            ].includes(state.auth.designation ?? "")
          : hasModulePermission(state.auth, "creative_management")
      }
      redirectTo="/"
    >
      <CreativeManagementLayout />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle", {
        ns: "creativeManagement",
      })} - ${i18next.t("label.hitseeker", { ns: "common" })}`,
    title: () =>
      i18next.t("pageTitle", {
        ns: "creativeManagement",
      }),
    crumb: () =>
      i18next.t("pageTitle", {
        ns: "creativeManagement",
      }),
  },
}

export default route
