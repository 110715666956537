import { api as generatedApi } from "./discussionForum.generated"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    "CategoryAndPriority",
    "ThreadList",
    "ThreadChat",
    "Thread",
    "Priority",
  ],
  endpoints: {
    GetCategoryAndPriorityList: {
      providesTags: () => [{ type: "CategoryAndPriority", id: "LIST" }],
    },
    GetThreadList: {
      providesTags: () => [{ type: "ThreadList", id: "LIST" }],
    },
    GetThreads: {
      providesTags: () => [{ type: "ThreadList", id: "LIST" }],
    },
    GetPriority: {
      providesTags: () => [{ type: "Priority", id: "LIST" }],
    },
    GetThreadChat: {
      providesTags: () => [{ type: "ThreadChat", id: "LIST" }],
    },
    GetChatItem: {
      providesTags: (result, error, arg) => [
        { type: "ThreadChat", id: arg.thread_id },
      ],
    },
    GetThreadSubject: {
      providesTags: (result, error, arg) => [
        { type: "ThreadList", id: arg.id },
      ],
    },
    GetCategoryAndPriorityIdList: {
      providesTags: () => [{ type: "CategoryAndPriority", id: "IDS" }],
    },
    GetCategory: {
      providesTags: (_, __, arg) => [
        { type: "Category", id: arg?.category_name },
      ],
    },
    GetPriorities: {
      providesTags: (_, __, arg) => [{ type: "Priority", id: "IDS" }],
    },
    CreatePriorityAdmin: {
      invalidatesTags: () => [{ type: "CategoryAndPriority", id: "LIST" }],
    },
    UpdatePriorityAdmin: {
      invalidatesTags: () => [{ type: "CategoryAndPriority", id: "LIST" }],
    },
    CreateCategoryAdmin: {
      invalidatesTags: () => [{ type: "CategoryAndPriority", id: "LIST" }],
    },
    UpdateCategoryAdmin: {
      invalidatesTags: () => [{ type: "CategoryAndPriority", id: "LIST" }],
    },
  },
})

export const {
  useGetCategoryAndPriorityListQuery,
  useGetThreadListQuery,
  useGetThreadChatQuery,
  useGetChatItemQuery,
  useGetThreadSubjectQuery,
  useCreatePriorityAdminMutation,
  useUpdatePriorityAdminMutation,
  useCreateCategoryAdminMutation,
  useUpdateCategoryAdminMutation,
  useGetPriorityQuery,
  useGetThreadsQuery,
  useGetCategoryAndPriorityIdListQuery,
  useGetParentCategoriesAndPrioritiesListQuery,
  useGetCategoryQuery,
  useGetPrioritiesQuery,
} = api

export const { GetThreadSubject } = api.endpoints
