/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetCategoryAndPriorityListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCategoryAndPriorityListQuery = { __typename?: 'Query', discussion_forum_priority?: Array<{ __typename?: 'DiscussionForumPriorityOutputType', priority_type: string, parent_id?: number, colour: string, id: string }>, discussion_forum_category?: Array<{ __typename?: 'DiscussionForumCategoryOutputType', category_name: string, parent_id?: number, id: string, is_ds_forum_category?: boolean }> };

export type GetThreadListQueryVariables = Types.Exact<{
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  category_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  priority_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  thread_status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search_text?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no: Types.Scalars['Int']['input'];
}>;


export type GetThreadListQuery = { __typename?: 'Query', discussion_forum_thread_total_objects?: number, discussion_forum_thread?: Array<{ __typename?: 'DiscussionForumThreadOutputType', unique_key: string, category_id: number, priority_id: number, subject: string, text: string, thread_status: string, id: string, created_at: any, user_details?: { __typename?: 'UserDetailsType', name?: string, profile_image?: string } }> };

export type GetThreadsQueryVariables = Types.Exact<{
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetThreadsQuery = { __typename?: 'Query', discussion_forum_thread?: Array<{ __typename?: 'DiscussionForumThreadOutputType', id: string, text: string, subject: string, unique_key: string, created_at: any, category_id: number, priority_id: number, thread_status: string, user_details?: { __typename?: 'UserDetailsType', name?: string, profile_image?: string } }> };

export type GetPriorityQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPriorityQuery = { __typename?: 'Query', discussion_forum_priority?: Array<{ __typename?: 'DiscussionForumPriorityOutputType', priority_type: string, colour: string, id: string, parent_id?: number }> };

export type GetThreadSubjectQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetThreadSubjectQuery = { __typename?: 'Query', discussion_forum_thread?: Array<{ __typename?: 'DiscussionForumThreadOutputType', id: string, subject: string }> };

export type GetThreadChatQueryVariables = Types.Exact<{
  thread_id: Types.Scalars['Int']['input'];
}>;


export type GetThreadChatQuery = { __typename?: 'Query', discussion_forum_thread?: Array<{ __typename?: 'DiscussionForumThreadOutputType', category_id: number, priority_id: number, unique_key: string, subject: string, text: string, thread_status: string, updated_at: any, created_at: any, user_details?: { __typename?: 'UserDetailsType', name?: string, profile_image?: string } }> };

export type GetChatItemQueryVariables = Types.Exact<{
  thread_id: Types.Scalars['Int']['input'];
}>;


export type GetChatItemQuery = { __typename?: 'Query', discussion_forum_chat_item?: Array<{ __typename?: 'DiscussionForumChatItemOutputType', user_id: number, text: string, id: string, created_at: any, updated_at: any, user_details?: { __typename?: 'UserDetailsType', name?: string, profile_image?: string } }> };

export type GetCategoryAndPriorityIdListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCategoryAndPriorityIdListQuery = { __typename?: 'Query', discussion_forum_priority?: Array<{ __typename?: 'DiscussionForumPriorityOutputType', id: string }>, discussion_forum_category?: Array<{ __typename?: 'DiscussionForumCategoryOutputType', id: string }> };

export type GetCategoryQueryVariables = Types.Exact<{
  category_name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  hitseeker_locale?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetCategoryQuery = { __typename?: 'Query', discussion_forum_category?: Array<{ __typename?: 'DiscussionForumCategoryOutputType', id: string, category_name: string }> };

export type GetPrioritiesQueryVariables = Types.Exact<{
  hitseeker_locale?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPrioritiesQuery = { __typename?: 'Query', discussion_forum_priority?: Array<{ __typename?: 'DiscussionForumPriorityOutputType', id: string }> };

export type GetParentCategoriesAndPrioritiesListQueryVariables = Types.Exact<{
  hitseeker_locale?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetParentCategoriesAndPrioritiesListQuery = { __typename?: 'Query', discussion_forum_category?: Array<{ __typename?: 'DiscussionForumCategoryOutputType', id: string, parent_id?: number, category_name: string }>, discussion_forum_priority?: Array<{ __typename?: 'DiscussionForumPriorityOutputType', id: string, parent_id?: number, priority_type: string, colour: string }> };

export type CreatePriorityAdminMutationVariables = Types.Exact<{
  colour: Types.Scalars['String']['input'];
  priority_type: Types.Scalars['String']['input'];
  parent_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CreatePriorityAdminMutation = { __typename?: 'Mutation', create_discussion_forum_priority_type?: { __typename?: 'CreateDiscussionForumPriority', ok?: boolean } };

export type UpdatePriorityAdminMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  colour: Types.Scalars['String']['input'];
  priority_type: Types.Scalars['String']['input'];
  parent_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdatePriorityAdminMutation = { __typename?: 'Mutation', update_discussion_forum_priority_type?: { __typename?: 'UpdateDiscussionForumPriority', ok?: boolean } };

export type CreateCategoryAdminMutationVariables = Types.Exact<{
  category_name: Types.Scalars['String']['input'];
  parent_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CreateCategoryAdminMutation = { __typename?: 'Mutation', create_discussion_forum_category?: { __typename?: 'CreateDiscussionForumCategory', ok?: boolean } };

export type UpdateCategoryAdminMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  category_name: Types.Scalars['String']['input'];
  parent_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateCategoryAdminMutation = { __typename?: 'Mutation', update_discussion_forum_category?: { __typename?: 'UpdateDiscussionForumCategory', ok?: boolean } };


export const GetCategoryAndPriorityListDocument = `
    query GetCategoryAndPriorityList {
  discussion_forum_priority {
    priority_type
    parent_id
    colour
    id
  }
  discussion_forum_category {
    category_name
    parent_id
    id
    is_ds_forum_category
  }
}
    `;
export const GetThreadListDocument = `
    query GetThreadList($organisation_id: Int, $category_id: Int, $priority_id: Int, $thread_status: String, $search_text: String, $page_size: Int, $page_no: Int!) {
  discussion_forum_thread(
    filters: {organisation_id: $organisation_id, category_id: $category_id, priority_id: $priority_id, thread_status: $thread_status, search_text: $search_text}
    page_size: $page_size
    page_no: $page_no
    sort_by: "updated_at"
    sort_dir: "desc"
  ) {
    unique_key
    category_id
    priority_id
    subject
    text
    thread_status
    id
    created_at
    user_details {
      name
      profile_image
    }
  }
  discussion_forum_thread_total_objects(
    filters: {organisation_id: $organisation_id, category_id: $category_id, priority_id: $priority_id, thread_status: $thread_status, search_text: $search_text}
  )
}
    `;
export const GetThreadsDocument = `
    query GetThreads($organisation_id: Int, $page_size: Int, $sort_by: String, $sort_dir: String) {
  discussion_forum_thread(
    filters: {organisation_id: $organisation_id}
    page_size: $page_size
    sort_by: $sort_by
    sort_dir: $sort_dir
  ) {
    id
    text
    subject
    unique_key
    created_at
    category_id
    priority_id
    thread_status
    user_details {
      name
      profile_image
    }
  }
}
    `;
export const GetPriorityDocument = `
    query GetPriority {
  discussion_forum_priority {
    priority_type
    colour
    id
    parent_id
  }
}
    `;
export const GetThreadSubjectDocument = `
    query GetThreadSubject($id: Int!) {
  discussion_forum_thread(filters: {id: $id}) {
    id
    subject
  }
}
    `;
export const GetThreadChatDocument = `
    query GetThreadChat($thread_id: Int!) {
  discussion_forum_thread(filters: {id: $thread_id}) {
    category_id
    priority_id
    unique_key
    subject
    text
    thread_status
    updated_at
    created_at
    user_details {
      name
      profile_image
    }
  }
}
    `;
export const GetChatItemDocument = `
    query GetChatItem($thread_id: Int!) {
  discussion_forum_chat_item(
    thread_id: $thread_id
    sort_by: "updated_at"
    sort_dir: "asc"
  ) {
    user_id
    text
    id
    created_at
    updated_at
    user_details {
      name
      profile_image
    }
  }
}
    `;
export const GetCategoryAndPriorityIdListDocument = `
    query GetCategoryAndPriorityIdList {
  discussion_forum_priority(filters: {hitseeker_locale: "en-GB"}) {
    id
  }
  discussion_forum_category(filters: {hitseeker_locale: "en-GB"}) {
    id
  }
}
    `;
export const GetCategoryDocument = `
    query GetCategory($category_name: String, $hitseeker_locale: String = "en-GB") {
  discussion_forum_category(
    filters: {category_name: $category_name, hitseeker_locale: $hitseeker_locale}
  ) {
    id
    category_name
  }
}
    `;
export const GetPrioritiesDocument = `
    query GetPriorities($hitseeker_locale: String = "en-GB") {
  discussion_forum_priority(filters: {hitseeker_locale: $hitseeker_locale}) {
    id
  }
}
    `;
export const GetParentCategoriesAndPrioritiesListDocument = `
    query GetParentCategoriesAndPrioritiesList($hitseeker_locale: String) {
  discussion_forum_category(filters: {hitseeker_locale: $hitseeker_locale}) {
    id
    parent_id
    category_name
  }
  discussion_forum_priority(filters: {hitseeker_locale: $hitseeker_locale}) {
    id
    parent_id
    priority_type
    colour
  }
}
    `;
export const CreatePriorityAdminDocument = `
    mutation CreatePriorityAdmin($colour: String!, $priority_type: String!, $parent_id: Int) {
  create_discussion_forum_priority_type(
    colour: $colour
    priority_type: $priority_type
    parent_id: $parent_id
  ) {
    ok
  }
}
    `;
export const UpdatePriorityAdminDocument = `
    mutation UpdatePriorityAdmin($id: Int!, $colour: String!, $priority_type: String!, $parent_id: Int) {
  update_discussion_forum_priority_type(
    id: $id
    colour: $colour
    priority_type: $priority_type
    parent_id: $parent_id
  ) {
    ok
  }
}
    `;
export const CreateCategoryAdminDocument = `
    mutation CreateCategoryAdmin($category_name: String!, $parent_id: Int) {
  create_discussion_forum_category(
    category_name: $category_name
    parent_id: $parent_id
  ) {
    ok
  }
}
    `;
export const UpdateCategoryAdminDocument = `
    mutation UpdateCategoryAdmin($id: Int!, $category_name: String!, $parent_id: Int) {
  update_discussion_forum_category(
    id: $id
    category_name: $category_name
    parent_id: $parent_id
  ) {
    ok
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCategoryAndPriorityList: build.query<GetCategoryAndPriorityListQuery, GetCategoryAndPriorityListQueryVariables | void>({
      query: (variables) => ({ document: GetCategoryAndPriorityListDocument, variables })
    }),
    GetThreadList: build.query<GetThreadListQuery, GetThreadListQueryVariables>({
      query: (variables) => ({ document: GetThreadListDocument, variables })
    }),
    GetThreads: build.query<GetThreadsQuery, GetThreadsQueryVariables | void>({
      query: (variables) => ({ document: GetThreadsDocument, variables })
    }),
    GetPriority: build.query<GetPriorityQuery, GetPriorityQueryVariables | void>({
      query: (variables) => ({ document: GetPriorityDocument, variables })
    }),
    GetThreadSubject: build.query<GetThreadSubjectQuery, GetThreadSubjectQueryVariables>({
      query: (variables) => ({ document: GetThreadSubjectDocument, variables })
    }),
    GetThreadChat: build.query<GetThreadChatQuery, GetThreadChatQueryVariables>({
      query: (variables) => ({ document: GetThreadChatDocument, variables })
    }),
    GetChatItem: build.query<GetChatItemQuery, GetChatItemQueryVariables>({
      query: (variables) => ({ document: GetChatItemDocument, variables })
    }),
    GetCategoryAndPriorityIdList: build.query<GetCategoryAndPriorityIdListQuery, GetCategoryAndPriorityIdListQueryVariables | void>({
      query: (variables) => ({ document: GetCategoryAndPriorityIdListDocument, variables })
    }),
    GetCategory: build.query<GetCategoryQuery, GetCategoryQueryVariables | void>({
      query: (variables) => ({ document: GetCategoryDocument, variables })
    }),
    GetPriorities: build.query<GetPrioritiesQuery, GetPrioritiesQueryVariables | void>({
      query: (variables) => ({ document: GetPrioritiesDocument, variables })
    }),
    GetParentCategoriesAndPrioritiesList: build.query<GetParentCategoriesAndPrioritiesListQuery, GetParentCategoriesAndPrioritiesListQueryVariables | void>({
      query: (variables) => ({ document: GetParentCategoriesAndPrioritiesListDocument, variables })
    }),
    CreatePriorityAdmin: build.mutation<CreatePriorityAdminMutation, CreatePriorityAdminMutationVariables>({
      query: (variables) => ({ document: CreatePriorityAdminDocument, variables })
    }),
    UpdatePriorityAdmin: build.mutation<UpdatePriorityAdminMutation, UpdatePriorityAdminMutationVariables>({
      query: (variables) => ({ document: UpdatePriorityAdminDocument, variables })
    }),
    CreateCategoryAdmin: build.mutation<CreateCategoryAdminMutation, CreateCategoryAdminMutationVariables>({
      query: (variables) => ({ document: CreateCategoryAdminDocument, variables })
    }),
    UpdateCategoryAdmin: build.mutation<UpdateCategoryAdminMutation, UpdateCategoryAdminMutationVariables>({
      query: (variables) => ({ document: UpdateCategoryAdminDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCategoryAndPriorityListQuery, useLazyGetCategoryAndPriorityListQuery, useGetThreadListQuery, useLazyGetThreadListQuery, useGetThreadsQuery, useLazyGetThreadsQuery, useGetPriorityQuery, useLazyGetPriorityQuery, useGetThreadSubjectQuery, useLazyGetThreadSubjectQuery, useGetThreadChatQuery, useLazyGetThreadChatQuery, useGetChatItemQuery, useLazyGetChatItemQuery, useGetCategoryAndPriorityIdListQuery, useLazyGetCategoryAndPriorityIdListQuery, useGetCategoryQuery, useLazyGetCategoryQuery, useGetPrioritiesQuery, useLazyGetPrioritiesQuery, useGetParentCategoriesAndPrioritiesListQuery, useLazyGetParentCategoriesAndPrioritiesListQuery, useCreatePriorityAdminMutation, useUpdatePriorityAdminMutation, useCreateCategoryAdminMutation, useUpdateCategoryAdminMutation } = injectedRtkApi;

