import ProtectedRoute from "@/components/ProtectedRoute"
import gameDetailsRoute from "@/pages/Games/GameDetails/route"
import submissionRoute from "@/pages/Games/Submission/route"
import kwaleeFeedbackRoute from "@/pages/Games/Feedback/route"
import cpiListRoute from "@/pages/Games/CPI/route"
import { hasModuleRoutePermission } from "@/util/permissions"
import gameKeyRoute from "@/pages/Games/GameKeys/route"
import creativeManagementRoute from "@/pages/CreativeManagement/route"
import { lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import i18next from "i18next"

const MyGamesPage = lazy(() => import("@/pages/Games/MyGamesIndex"))

const route: RouteObject = {
  path: "/games",
  element: (
    <ProtectedRoute
      hasPermission={hasModuleRoutePermission("my_games")}
      redirectTo="/"
    >
      <Outlet />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("navigation.myGames", { ns: "common" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    title: () => i18next.t("navigation.myGames", { ns: "common" }),
    crumb: () => i18next.t("navigation.myGames", { ns: "common" }),
  },
  children: [
    {
      index: true,
      element: <MyGamesPage />,
    },
    gameDetailsRoute,
    submissionRoute,
    kwaleeFeedbackRoute,
    gameKeyRoute,
    cpiListRoute,
    creativeManagementRoute,
  ],
}

export default route
