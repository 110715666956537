import i18next from "i18next"

export const USER_STATUSES = [
  {
    status: "active",
    // title: i18next.t("label.active", { ns: "common" }),
    get title() {
      return i18next.t("label.active", { ns: "common" })
    },
    flagColor: "#47CFAF",
  },
  {
    status: "inactive",
    // title: i18next.t("label.pending", { ns: "common" }),
    get title() {
      return i18next.t("label.pending", { ns: "common" })
    },
    flagColor: "#BDBDBD",
  },
  {
    status: "blocked",
    // title: i18next.t("label.blocked", { ns: "common" }),
    get title() {
      return i18next.t("label.blocked", { ns: "common" })
    },
    flagColor: "#FAA51A",
  },
]

export const USER_STATUS_COLOR_MAP = USER_STATUSES.reduce(
  (acc: Record<string, string>, userStatus) => ({
    ...acc,
    [userStatus.status]: userStatus.flagColor,
  }),
  {},
)

// export const USER_STATUS_TITLE_MAP = USER_STATUSES.reduce(
//   (acc: Record<string, string>, userStatus) => ({
//     ...acc,
//     get [userStatus.status]() {
//       console.log(userStatusGetter[userStatus.status](), "chala")
//       return userStatusGetter[userStatus.status]()
//     },
//   }),
//   {},
// )

export const USER_STATUS_TITLE_MAP: Record<string, string> = {
  get active() {
    return i18next.t("label.active", { ns: "common" })
  },
  get inactive() {
    return i18next.t("label.pending", { ns: "common" })
  },
  get blocked() {
    return i18next.t("label.blocked", { ns: "common" })
  },
}

export enum USER_ACTIONS {
  none,
  editDesignation,
  editRole,
  editProject,
  editStatus,
}

export const PRODUCTION = "production"
export const PRODUCT = "product"
export const BUSINESS_DEVELOPMENT = "business development"
export const DEVELOPER = "developer"
export const DESIGNER = "designer"
export const PRODUCT_TOOLS = "product tools"
export const PUBLISHING_MANAGER = "publishing manager"
export const ADMIN = "admin"
export const PRODUCT_MARKETING_MANAGER = "product marketing manager"
export const CREATIVE_TEAM = "creative team"

export const USER_ROLES = [
  { title: DEVELOPER, value: DEVELOPER },
  { title: BUSINESS_DEVELOPMENT, value: BUSINESS_DEVELOPMENT },
  { title: DESIGNER, value: DESIGNER },
  { title: PRODUCT_TOOLS, value: PRODUCT_TOOLS },
  { title: PRODUCTION, value: PRODUCTION },
  { title: PUBLISHING_MANAGER, value: PUBLISHING_MANAGER },
  { title: PRODUCT, value: PRODUCT },
  { title: ADMIN, value: ADMIN },
  { title: PRODUCT_MARKETING_MANAGER, value: PRODUCT_MARKETING_MANAGER },
  { title: CREATIVE_TEAM, value: CREATIVE_TEAM },
]
