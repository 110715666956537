import { Theme } from "@mui/material"

const defaultOverrides: NonNullable<Theme["components"]>["MuiTextField"] = {
  defaultProps: {
    variant: "standard",
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      disableUnderline: true,
    },
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "& .MuiInputBase-root": { marginTop: 0 },
      "& .MuiInputBase-root.Mui-error": { borderColor: "#F64B3C" },

      "input, textarea, .MuiSelect-standard": {
        fontSize: 14,
        marginTop: theme.spacing(2),
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none",
        },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      ...(ownerState.variant === "outlined" && {
        label: {
          fontSize: 14,
          textTransform: "capitalize",
        },
        input: {
          fontSize: 14,
          marginTop: 0,
        },
      }),
      ...(ownerState.variant === "filled" && {
        "& .MuiFilledInput-root": {
          borderRadius: 8,
          border: `1px solid`,
          borderColor: theme.palette.tertiary.light,
          backgroundColor:
            theme.palette.theme === "dark" ? "#313131" : "#F5F5F7",
          ":focus": {
            backgroundColor: "inherit",
          },
          svg: {
            color: theme.palette.theme === "dark" ? "#A0A0A0" : "#1E1E1E",
          },
          input: {
            background: theme.palette.theme === "dark" ? "#19191A" : "#FFF",
            width: "100%",
            borderRadius: 4,
            outline: 0,
            padding: 4,
            border: `1px solid transparent`,
            margin: "6px 6px 6px 20px",
            fontSize: 14,

            ":focus": {
              border: `1px solid`,
              borderColor: theme.palette.tertiary.light,
            },
          },
        },
      }),
      ...(ownerState.variant === "outlined" && {
        label: {
          fontSize: 18,
          textTransform: "capitalize",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.tertiary.light,
        },
      }),
    }),
  },
}

export default defaultOverrides
