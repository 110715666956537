import { RouteObject } from "react-router-dom"
import { lazy } from "react"
import i18next from "i18next"

const UpdateCampaign = lazy(
  () => import("@/pages/UnityTest/CampaignUpdate/CampaignUpdate"),
)

const route: RouteObject = {
  path: "/unity-test/:campaignId",
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle", { ns: "unityTest" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    title: () => i18next.t("label.updateCampaign", { ns: "unityTest" }),
    crumb: () => i18next.t("label.updateCampaign", { ns: "unityTest" }),
  },
  element: <UpdateCampaign />,
}

export default route
