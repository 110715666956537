import { lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import ProtectedRoute from "@/components/ProtectedRoute"
import i18next from "i18next"
// import { hasUnityTestPermission } from "@/util/permissions"
import unityGoalRoute from "@/pages/UnityTest/CampaignGoals/route"
import unityCampaignRoute from "@/pages/UnityTest/CampaignUpdate/route"

const UnityTest = lazy(() => import("@/pages/UnityTest/UnityTest"))

const route: RouteObject = {
  path: "/unity-test",
  element: (
    <ProtectedRoute hasPermission={false} redirectTo="/">
      <Outlet />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle", { ns: "unityTest" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    title: () => i18next.t("pageTitle", { ns: "unityTest" }),
    crumb: () => i18next.t("pageTitle", { ns: "unityTest" }),
  },
  children: [
    { index: true, element: <UnityTest /> },
    unityGoalRoute,
    unityCampaignRoute,
  ],
}

export default route
