import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

import { SUPPORTED_LANGUAGES } from "@/constants/i18n"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      "common",
      "dashboard",
      "analytics",
      "games",
      "cpiTest",
      "feedback",
      "organisation",
      "onboarding",
      "fileManagement",
      "discussionForum",
      "notification",
      "knowledgeBase",
      "customEvents",
      "creativeManagement",
      "unityTest",
    ],
    fallbackLng: "en-GB",
    supportedLngs: SUPPORTED_LANGUAGES,
    detection: {
      order: ["path"],
    },
    interpolation: {
      escapeValue: false,
    },
  })
